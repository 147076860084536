<template>
  <v-card id="account-setting-card">
    <div id="callLogs" style="padding: 30px">
      <h1>Callogs</h1>
      <v-btn class="ma-2" outlined color="teal" @click="tabeRe()"> <v-icon>mdi-flash</v-icon>Refresh </v-btn>
      <v-app id="inspire">
        <v-card>
          <v-card-title>
            Call Logs
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :loading="!myitems.length"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="myitems"
            :search="search"
            sort-field="durations"
          ></v-data-table>
        </v-card>
      </v-app>
    </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
import { doc, getDoc } from 'firebase/firestore'
import { dbRef, turboSearchClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
export default {
  name: 'CallLogs',
  data() {
    return {
      search: '',
      myitems: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'fileName',
        },
        { text: 'File Path', value: 'absolutePath' },
        { text: 'FileSize', value: 'fileSize' },
        { text: 'Time', value: 'time' },
      ],
      desserts: [
        {
          deviceID: 'Frozen Yogurt',
          durations: 159,
          name: 6.0,
          carbs: 24,
          number: 4.0,
          time: '1%',
        },
      ],
    }
  },
  mounted: function () {
    turboSearchClick()
    this.getCallLogsList()
  },
  methods: {
    async getCallLogsList() {
      this.$store.commit('LOADER', true)
      let uid = getUID()
      const myDoc = doc(dbRef, 'UserFilePath', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          let listOfApps = res.data()
          console.log(listOfApps)
          Object.values(listOfApps).map(i => {
            this.myitems.push(i)
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
  },
}
</script>